import { template as template_93321ba5d8414015a1bcd5877e3af6ee } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_93321ba5d8414015a1bcd5877e3af6ee(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
