import { template as template_be2ec63744124e8bb9d24b0e59c1c53d } from "@ember/template-compiler";
const SidebarSectionMessage = template_be2ec63744124e8bb9d24b0e59c1c53d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
