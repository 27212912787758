define("discourse/plugins/discourse-gamification/discourse/components/gamification-leaderboard-row", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="user {{if this.rank.currentUser 'user-highlight'}}"
    id="leaderboard-user-{{this.rank.id}}"
  >
    <div class="user__rank">{{this.rank.position}}</div>
    <div
      class="user__avatar clickable"
      role="button"
      data-user-card={{this.rank.username}}
    >
      {{avatar this.rank imageSize="large"}}
      <span class="user__name">
        {{#if this.siteSettings.prioritize_username_in_ux}}
          {{this.rank.username}}
        {{else}}
          {{or this.rank.name this.rank.username}}
        {{/if}}
      </span>
    </div>
    <div class="user__score">
      {{#if this.site.mobileView}}
        {{number this.rank.total_score}}
      {{else}}
        {{fullnumber this.rank.total_score}}
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "BcWNNZ92",
    "block": "[[[10,0],[15,0,[29,[\"user \",[52,[30,0,[\"rank\",\"currentUser\"]],\"user-highlight\"]]]],[15,1,[29,[\"leaderboard-user-\",[30,0,[\"rank\",\"id\"]]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"user__rank\"],[12],[1,[30,0,[\"rank\",\"position\"]]],[13],[1,\"\\n  \"],[10,0],[14,0,\"user__avatar clickable\"],[14,\"role\",\"button\"],[15,\"data-user-card\",[30,0,[\"rank\",\"username\"]]],[12],[1,\"\\n    \"],[1,[28,[35,2],[[30,0,[\"rank\"]]],[[\"imageSize\"],[\"large\"]]]],[1,\"\\n    \"],[10,1],[14,0,\"user__name\"],[12],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"prioritize_username_in_ux\"]],[[[1,\"        \"],[1,[30,0,[\"rank\",\"username\"]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,4],[[30,0,[\"rank\",\"name\"]],[30,0,[\"rank\",\"username\"]]],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"user__score\"],[12],[1,\"\\n\"],[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"      \"],[1,[28,[35,5],[[30,0,[\"rank\",\"total_score\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,6],[[30,0,[\"rank\",\"total_score\"]]],null]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"if\",\"avatar\",\"span\",\"or\",\"number\",\"fullnumber\"]]",
    "moduleName": "discourse/plugins/discourse-gamification/discourse/components/gamification-leaderboard-row.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    tagName: "",
    rank: null
  }));
});