import { template as template_1f23abb6b23d4dbf95a352d767136f3f } from "@ember/template-compiler";
const FKControlMenuContainer = template_1f23abb6b23d4dbf95a352d767136f3f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
