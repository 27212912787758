import { template as template_57f5f7d978394f0dbeb5330d4f4b5c0c } from "@ember/template-compiler";
const FKLabel = template_57f5f7d978394f0dbeb5330d4f4b5c0c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
