define("discourse/plugins/discourse-reactions/discourse/templates/connectors/user-notifications-bottom/discourse-reactions-user-notification-reactions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.discourse_reactions_enabled}}
    <LinkTo @route="userNotifications.reactionsReceived">
      {{d-icon "far-smile"}}
      <span>{{i18n "discourse_reactions.reactions_title"}}</span>
    </LinkTo>
  {{/if}}
  */
  {
    "id": "gkGLECGB",
    "block": "[[[41,[30,0,[\"siteSettings\",\"discourse_reactions_enabled\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@route\"],[\"userNotifications.reactionsReceived\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[\"far-smile\"],null]],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,4],[\"discourse_reactions.reactions_title\"],null]],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"link-to\",\"d-icon\",\"span\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-reactions/discourse/templates/connectors/user-notifications-bottom/discourse-reactions-user-notification-reactions.hbs",
    "isStrictMode": false
  });
});